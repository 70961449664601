html {
  font-family: 'YuGothic' !important;
  font-style: normal;
  font-size: 16px;
}
body {
  font-family: 'YuGothic' !important;
  font-style: normal;
  font-size: 16px;
}
