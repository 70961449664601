@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .border-bottom-text {
    @apply font-bold text-[14px] border-b border-solid border-[#000000] cursor-pointer;
  }
  .item-report {
    @apply bg-white w-full mb-[14px] rounded-lg px-[18px] py-[11px] flex justify-between;
  }
}
